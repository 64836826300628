import * as React from 'react'
import { Field as FinalFormField, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { CountryCode, isSupportedCountry, validatePhoneNumberLength } from 'libphonenumber-js'
import { getExampleNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import { Flex, Field } from 'components/primitives'
import Margin from 'components/Margin'
import { PhoneNumber } from './FinalFormInputs'
import CountryCodeSelect from 'components/Select/CountryCodeSelect'
import { Maybe, SelectOption } from 'types/global'
import { parsePhoneNumber } from 'redux/ticket/ticketHelpers'
import { isRequired } from 'utils/validators'
import { isEmpty } from 'lodash-es'

type Props = {
  label: string
  smallLabel?: string
  countryCodeName?: string
  numberName?: string
  defaultPhoneNumber?: string
  defaultPhoneCountryCode?: Maybe<SelectOption<string>>
}

export function Phone({
  countryCodeName = 'countryCode',
  numberName = 'number',
  label,
  smallLabel,
  defaultPhoneNumber = '',
  defaultPhoneCountryCode,
}: Props) {
  const { t } = useTranslation()
  const form = useForm()
  const [selectedCountry, setSelectedCountry] = React.useState<string>('')

  let defaultCountry = !isEmpty(selectedCountry)
    ? (selectedCountry.toUpperCase() as CountryCode)
    : (defaultPhoneCountryCode?.value.toUpperCase() as CountryCode)
  const exampleNumber = getExampleNumber(defaultCountry, examples)
  const defaultPhone = parseAndTrimPhoneNumber(defaultPhoneNumber, defaultCountry)

  React.useEffect(() => {
    if (selectedCountry) {
      form.change('number', defaultPhone?.nationalNumber)
    }
  }, [selectedCountry])

  return (
    <Margin use={Flex} spacing="md" style={{ flex: 1 }}>
      <Field style={{ flex: 4 }}>
        <CountryCodeSelect
          label={t('Country')}
          name={countryCodeName}
          spacing="none"
          defaultValue={defaultPhoneCountryCode as any}
        />
      </Field>
      <FinalFormField name={countryCodeName} subscription={{ value: true }}>
        {({ input: { value: option } }) => {
          setSelectedCountry((option.value ? option.value.toUpperCase() : option?.toUpperCase()) as CountryCode)

          return (
            <Field style={{ flex: 5 }}>
              <div style={{ fontSize: '0.72rem' }}>{smallLabel}</div>
              <PhoneNumber
                label={label}
                name={numberName}
                spacing="none"
                exampleNumber={exampleNumber}
                defaultPhone={defaultPhone}
                selectedCountry={isSupportedCountry(selectedCountry) ? selectedCountry : 'US'}
                validate={isRequired(t('Contact number required'))}
              />
            </Field>
          )
        }}
      </FinalFormField>
    </Margin>
  )
}

function parseAndTrimPhoneNumber(phoneNumber, country) {
  let validateResult = validatePhoneNumberLength(phoneNumber, country)
  let trimmedNumber = phoneNumber

  while (validateResult === 'TOO_LONG') {
    trimmedNumber = trimmedNumber.substring(0, trimmedNumber.length - 1)
    validateResult = validatePhoneNumberLength(trimmedNumber, country)
  }

  return parsePhoneNumber(trimmedNumber, country)
}

export default Phone
