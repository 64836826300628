import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Exchange } from 'types/callstore'
import Margin from 'components/Margin'
import { Heading, Label, Paragraph } from 'components/primitives'
import { DynamoEvents } from 'types/events'

export type ExchangeEventProps = {
  exchangeBrand: string
  formattedPhone: string
  addressLines: string[]
  formattedCityStateZip: string
  locationNameTranslation: string
  branchId: string
} & Exchange

const ExchangeEvent = (event: DynamoEvents) => {
  const {
    notes,
    bypassReason,
    formattedPhone,
    locationNameTranslation,
    addressLines,
    formattedCityStateZip,
    vehicleAccommodation,
    vehicleAccommodationSelect,
    accommodationNotes,
    disabledVehicle,
  } = event.attributes

  const rentalBrand = event.serviceLocations.exchangeLocation?.brand
  const rentalGPBR = event.serviceLocations.exchangeLocation?.additional_data.group_branch_number

  const { t } = useTranslation()
  return (
    <>
      <Heading as="h6" spacing="md">
        {t('driveInExchangeHeading')}
      </Heading>
      <Label>{t('labels.vehicleAccommodationSelect')}</Label>
      <Paragraph spacing="md">{vehicleAccommodationSelect?.label || vehicleAccommodation || 'N/A'}</Paragraph>
      <Label>{t('labels.accommodationNotes')}</Label>
      <Paragraph spacing="md">{accommodationNotes}</Paragraph>
      <Label>{t('labels.disabledVehicleSelect')}</Label>
      <Paragraph spacing="md">{disabledVehicle}</Paragraph>
      <Label>{t('labels.exchangeBranch')}</Label>
      <Margin spacing="md">
        <Paragraph spacing="sm">
          {`${rentalBrand} ${locationNameTranslation ? '- ' + locationNameTranslation : ''} ${rentalGPBR ? '- ' + rentalGPBR : ''}`}
        </Paragraph>
        <Paragraph spacing="sm">{formattedPhone}</Paragraph>
        <div>
          {addressLines}
          <div>{formattedCityStateZip}</div>
        </div>
      </Margin>
      <Label>{t('labels.describeIssue')}</Label>
      <Paragraph spacing="md">{notes}</Paragraph>
      {bypassReason ? (
        <>
          <Label>{t('labels.bypassReasonSelect')}</Label>
          <Paragraph spacing="md">{bypassReason}</Paragraph>
        </>
      ) : null}
    </>
  )
}

export default ExchangeEvent
