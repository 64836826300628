import * as React from 'react'
import { I18nextProvider } from 'react-i18next'
import { IconContext } from 'react-icons'
import { ThemeProvider } from 'styled-components/macro'
import { Provider as ReduxProvider } from 'react-redux'
import { getStore } from './redux/appStore'
import i18n from './i18n'
import theme from './theme'
import { pxToRem } from 'theme/utils'
import { MsalProvider } from '@azure/msal-react'
import { createAccessTokenContext } from 'components/Auth/TokenContext'
import { PublicClientApplication, EventType, AuthenticationResult } from '@azure/msal-browser'
import { getAzureConfig } from 'components/Auth/getAzureConfig'
import LaunchDarklyProvider from 'LaunchDarklyProvider'
import LocationProvider from 'components/LocationProvider'
import { HeroPendoCallIdProvider } from 'hooks/pendo/usePendoCallId'

const iconDefaults = {
  size: pxToRem(24),
}

type Props = {
  children: React.ReactNode
}

/**
 * Initialize the MSAL PCA instance outside of app to prevent re-rendering.
 */
export const msalInstance = new PublicClientApplication(getAzureConfig())
msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      msalInstance.setActiveAccount(account)
    }
  })
})

const store = getStore()
export const accessTokenContext = createAccessTokenContext()

const AppProviders = ({ children }: Props) => (
  <LocationProvider>
    <MsalProvider instance={msalInstance}>
      <ReduxProvider store={store}>
        <accessTokenContext.Provider>
          <LaunchDarklyProvider>
            <ThemeProvider theme={theme}>
              {/** @ts-ignore **/}
              <I18nextProvider i18n={i18n}>
                <IconContext.Provider value={iconDefaults}>
                  <HeroPendoCallIdProvider>{children}</HeroPendoCallIdProvider>
                </IconContext.Provider>
              </I18nextProvider>
            </ThemeProvider>
          </LaunchDarklyProvider>
        </accessTokenContext.Provider>
      </ReduxProvider>
    </MsalProvider>
  </LocationProvider>
)

export default AppProviders
