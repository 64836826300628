import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TransformedDriver } from 'types/ticket'
import Input from 'components/Input'
import { Condition, Error } from 'components/fields'
import Margin from 'components/Margin'

type DriversProps = {
  drivers: TransformedDriver[]
}
const DriversRadio: React.FC<DriversProps> = ({ drivers }) => {
  const { t } = useTranslation()
  return (
    <>
      {drivers.map((driver, i) => {
        const { firstName, lastName } = driver
        return (
          <Margin spacing="md" key={i}>
            <Input.Radio name="renterDriver" value={`driver-${i}` as any}>
              {firstName} {lastName}
            </Input.Radio>
            <Condition when="renterDriver" is={`driver-${i}`}>
              <Input.Hidden name="driverFirstName" initialValue={firstName as any} />
              <Input.Hidden name="driverLastName" initialValue={lastName as any} />
              <Input.Hidden name="driver" initialValue={driver as any} />
              <Input.Hidden name="driverCallbackNumber" initialValue={driver.phone?.[0]?.number || ''} />
            </Condition>
          </Margin>
        )
      })}
      <Margin spacing="md">
        <Input.Radio name="renterDriver" value={'other' as any}>
          {t('Other')}
        </Input.Radio>
      </Margin>
      <Error name="renterDriver" />
      <Margin spacing="md">
        <Condition when="renterDriver" is="other">
          <Input label={t('ldr.claim.driverFirstName')} name="driverFirstName" />
          <Input label={t('ldr.claim.driverLastName')} name="driverLastName" />
          <Input label={t('ldr.claim.driverCallbackNumber')} name="driverCallbackNumber" />
          <Input.Hidden name="driver" initialValue={null as any} />
        </Condition>
      </Margin>
    </>
  )
}

export default DriversRadio
