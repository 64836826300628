import React, { FunctionComponent, useCallback } from 'react'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from 'redux/appStore'
import { useServiceProxy } from 'hooks/kong'
import { IncomingGuiConfigResponse } from 'types/api'
import { GuiConfigResponse } from 'types/launchDarkly'
import { deepMapSanitize } from 'utils/deepMap'
import { setLaunchDarklyId } from 'redux/slices/configSlice'

export interface LaunchDarklyService {
  getLdProvider: () => Promise<FunctionComponent<{ children: React.ReactNode }>>
}

const flags: LDFlagSet = {
  'roadside-is-admin': false,
  'roadside-east-2-kong-header': '',
  'roadside-is-live-east-2': false,
  'roadside-is-staging-east-2': true,
  'roadside-is-null-driver': false,
  'roadside-is-aaa-hook-active': false,
  'roadside-is-uly-hook-active': false,
  'roadside-hotels-enabled': false,
  'roadside-hotels-enabled-admin-only': false,
}

export const useLdProvider = (): LaunchDarklyService => {
  const currentUser = useSelector(selectCurrentUser)
  const serviceProxy = useServiceProxy()
  const dispatch = useDispatch()

  const getLdProvider = useCallback(async (): Promise<FunctionComponent<{ children: React.ReactNode }>> => {
    try {
      const { data } = await serviceProxy<IncomingGuiConfigResponse>('get', '/serviceproxy/gui-config')
      const guiConfigResponse = deepMapSanitize(data) as GuiConfigResponse
      const { ldClientSideId } = guiConfigResponse
      dispatch(setLaunchDarklyId({ ldClientSideId }))
      return await asyncWithLDProvider({
        clientSideID: ldClientSideId,
        flags,
        reactOptions: {
          sendEventsOnFlagRead: false,
        },
        context: {
          kind: 'user',
          key: currentUser?.nameID ?? 'anonymous',
        },
      })
    } catch (error) {
      console.log(error)
    }
    return ({ children }) => {
      return <>{children}</>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])
  return { getLdProvider }
}
