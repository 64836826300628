import { FormState } from 'final-form'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { EventTypes } from 'types/events'
import { CallTypes } from 'types/callstore'
import Complete from 'components/Complete'
import Submit from 'components/fields/Submit'
import Input, { YesNoRadio, YesNoTriggers } from 'components/Input'
import Margin from 'components/Margin'
import { Heading, Paragraph, Label } from 'components/primitives'
import SmartAssignment from 'components/SmartAssignment'
import { BackNavigation } from 'components/Step'
import { WorkflowAction } from 'components/Workflow'
import ScheduledAt from 'components/ScheduledAt'
import IncidentLocation from 'components/IncidentLocation'
import { Condition } from 'components/fields'
import { useCallEvent } from 'hooks/events/call/useCallEvents'
import useCreateServiceEvent from 'hooks/events/useCreateServiceEvent'
import useCreateSoftService from 'hooks/aaa/useCreateSoftService'
import useCreateUlySoftService from 'hooks/urgent.ly/useCreateUlySoftService'
import {
  setSmartAssignmentParticipant,
  setSmartAssignmentCustomDropoffDestination,
} from 'redux/smartAssignment/smartAssignmentSlice'
import useUpdateAssignment from 'hooks/assignment/useUpdateAssignment'
import useUrgentlyProvider from 'hooks/urgent.ly/useUrgentlyProvider'
import { useSelector } from 'react-redux'
import { selectFormsState, selectSmartAssignment } from 'redux/appStore'
import { isRequired } from 'utils/validators'
import TireChangeEvent from './TireChangeEvent'
import { BreakdownDetailsForm, BreakdownNote } from '../BreakdownDetails'
import { BreakdownOptions } from '../BreakdownDetails/BreakdownRadio'
import { useDispatch } from 'react-redux'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

export function DispatchTireChangeActions() {
  const { t } = useTranslation()
  const { tires } = useSelector(selectFormsState) as any
  const tireChangeEvent = useCallEvent(EventTypes.TIRES_CHANGE_SPARE)
  let safeToContinue =
    (tires?.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE && tires?.unsafe === 'no') ||
    (tires?.breakdownOption === BreakdownOptions.PARKINGGARAGE && tires?.garageAccessible === 'yes') ||
    (tires?.breakdownOption === BreakdownOptions.OTHER && tires?.accessible === 'yes')

  if (tireChangeEvent)
    return (
      <>
        <Margin spacing="lg">
          <TireChangeEvent {...tireChangeEvent.event!} />
        </Margin>
        <RepairOrExchangeActions additionalAction={t('tires.driveOnSpare')} />
      </>
    )

  return (
    <Margin spacing="md">
      <YesNoRadio name="changeNeeded" label={t('tires.isChangeNeeded')} />
      <Condition when="changeNeeded" is={YesNoTriggers.YES}>
        <BreakdownDetailsForm callType={CallTypes.TIRES} />
        <BreakdownNote
          breakdownOption={tires?.breakdownOption}
          unsafe={tires?.unsafe}
          garageAccessible={tires?.garageAccessible}
          accessible={tires?.accessible}
        />
        {safeToContinue && <TireSubmitOnly />}
      </Condition>
      <Condition when="changeNeeded" is={YesNoTriggers.NO}>
        <RepairOrExchangeActions additionalAction={t('tires.driveOnSpare')} />
      </Condition>
    </Margin>
  )
}

function TireSubmitOnly() {
  const { t } = useTranslation()
  const { isEnterpriseTicket } = useUrgentlyProvider()
  const createSoftService = useCreateSoftService(EventTypes.TIRES_CHANGE_SPARE)
  const createUlySoftService = useCreateUlySoftService(EventTypes.TIRES_CHANGE_SPARE)

  return (
    <>
      <Margin spacing="md">
        <ScheduledAt callType={CallTypes.TIRES} />
        <Input.Hidden name="scheduledAt" />
      </Margin>
      <Label>{`${t('notesToProvider.label')}*`}</Label>
      <Label>{t('tires.dispatchSuggestion')}</Label>
      <Input.Textarea
        placeholder={t('notesToProvider.placeholder')}
        name="notes"
        spacing="lg"
        validate={isRequired(t('required.notes'))}
        maxLength={240}
      />
      <Margin spacing="md">
        <Submit
          onSubmit={async (state, caller) => {
            const { secondaryProvider = false, scheduledAt } = state.values
            if (scheduledAt) {
              return await createUlySoftService(state, caller)
            } else {
              if (isEnterpriseTicket) {
                // AAA
                return secondaryProvider
                  ? await createUlySoftService(state, caller) //Secondary
                  : await createSoftService(state, caller) //Default
              } else {
                // Urgently
                return secondaryProvider
                  ? await createSoftService(state, caller) //Secondary
                  : await createUlySoftService(state, caller) //Default
              }
            }
          }}
        >
          {t('tires.dispatchTireChange')}
        </Submit>
      </Margin>
    </>
  )
}

export function RepairOrExchangeActions({ additionalAction }: { additionalAction?: string }) {
  const { t } = useTranslation()
  const [isDriveInRepair, setIsDriveInRepair] = React.useState(false)
  const [additionalSelected, selectAdditional] = React.useState(false)
  const exchangeEvent = useCallEvent(EventTypes.EXCHANGE)
  const tireEvent = useCallEvent(EventTypes.TIRES)
  const spareTireEvent = useCallEvent(EventTypes.TIRES_CHANGE_SPARE)

  return (
    <Margin spacing="md">
      {isDriveInRepair ? (
        <DriveInRepair exitOnClick={() => setIsDriveInRepair(false)} />
      ) : additionalSelected ? (
        <>
          <BackNavigation onClick={() => selectAdditional(false)} />
          <Label>{t('tires.customerChose')}</Label>
          <Paragraph spacing="md">{additionalAction}</Paragraph>
          <Input.Hidden name="additionalAction" defaultValue={additionalAction as any} />
          <Complete eventType={EventTypes.TIRES} previouslyCreatedEvent={spareTireEvent} />
        </>
      ) : (
        <>
          <Heading as="h5">{t('tires.selectService')}</Heading>
          <Margin spacing="md">
            {!tireEvent && (
              <WorkflowAction
                callType={CallTypes.EXCHANGE}
                eventType={EventTypes.EXCHANGE}
                label={t('tires.vehicleExchange')}
                required
              />
            )}
            {!exchangeEvent && (
              <WorkflowAction
                callType={CallTypes.TIRES}
                eventType={EventTypes.TIRES}
                label={t('tires.driveIn.repair')}
                onClick={() => setIsDriveInRepair(true)}
                required
              />
            )}
            {!tireEvent && !exchangeEvent && additionalAction && (
              <WorkflowAction
                callType={CallTypes.ADDITIONAL}
                eventType={EventTypes.ADDITIONAL}
                label={additionalAction}
                onClick={() => selectAdditional(true)}
                required
              />
            )}
          </Margin>
          <Complete eventType={EventTypes.TIRES} previouslyCreatedEvent={spareTireEvent} />
        </>
      )}
    </Margin>
  )
}

function DriveInRepair({ exitOnClick }: { exitOnClick: () => void }) {
  const { t } = useTranslation()
  const createServiceEvent = useCreateServiceEvent({ eventType: EventTypes.TIRES })
  const updateAssignment = useUpdateAssignment()
  const dispatch = useDispatch()
  const { assignment: smartAssignment, participant, customDropoffDestination } = useSelector(selectSmartAssignment)

  const onSubmit = async (state: FormState<any>) => {
    try {
      if (!smartAssignment || !participant) {
        if (customDropoffDestination) {
          const event = await createServiceEvent({
            ...state.values,
          })
          dispatch(setCurrentEvent({ eventType: EventTypes.TIRES, newEvent: event.data }))
          return null
        }
        throw new Error('Missing Smart Assignment Info')
      }
      await updateAssignment(state.values)

      const { assigneeEntityId, entity, location } = participant

      const event = await createServiceEvent({
        ...state.values,
        participant: {
          assigneeEntityId,
          entity,
          location,
        },
      })

      dispatch(setCurrentEvent({ eventType: EventTypes.TIRES, newEvent: event.data }))
      return null
    } catch (err) {
      console.error(err)
      return t('tires.driveIn.error')
    }
  }

  return (
    <>
      {customDropoffDestination ? (
        <>
          <BackNavigation
            onClick={() => {
              dispatch(setSmartAssignmentCustomDropoffDestination(null))
              dispatch(setSmartAssignmentParticipant(null))
            }}
          />
          <IncidentLocation label={t('tires.customDriveInLocation')} location={customDropoffDestination} spacing="md" />
          <Submit onSubmit={onSubmit}>{t('tires.driveIn.submit')}</Submit>
        </>
      ) : (
        <>
          <SmartAssignment exitOnClick={exitOnClick} />
          {participant && <Submit onSubmit={onSubmit}>{t('tires.driveIn.submit')}</Submit>}
        </>
      )}
    </>
  )
}
