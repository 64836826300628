import useTimerState from 'hooks/useTimerState'
import React, { createContext, useContext } from 'react'
import { ProviderProps } from 'types/auth'

export const createCallTimerContext = (initialTime: number = 0) => {
  const timerContext = createContext<number>(initialTime)

  const Provider = ({ children }: ProviderProps) => {
    const { createdAt } = useTimerState()
    const startTime: number = createdAt ? Date.now() - new Date(createdAt).getTime() : 0

    const [time, setTime] = React.useState(initialTime)
    const [start] = React.useState(Date.now() - startTime)

    React.useEffect(() => {
      const timerId = window.setInterval(() => {
        setTime(Date.now() - start)
      }, 1000)

      return () => {
        window.clearInterval(timerId)
      }
    }, [start])

    return <timerContext.Provider value={time}>{children}</timerContext.Provider>
  }

  const useCallTimerContext = () => useContext(timerContext)
  return { Provider, useCallTimerContext }
}
