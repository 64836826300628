import React from 'react'
import { v4 as uuid } from 'uuid'

type ContextType = {
  heroPendoCallId: string | null
  changeHeroPendoCallId: () => void
} | null

const HeroPendoCallIdContext = React.createContext<ContextType>(uuid())

export const HeroPendoCallIdProvider = ({ children }) => {
  const [heroPendoCallId, setHeroPendoCallId] = React.useState(uuid())

  const changeHeroPendoCallId = React.useCallback(() => {
    setHeroPendoCallId(uuid())
  }, [])

  return (
    <HeroPendoCallIdContext.Provider value={{ heroPendoCallId, changeHeroPendoCallId }}>
      {children}
    </HeroPendoCallIdContext.Provider>
  )
}

export const usePendoCallId = () => {
  const context = React.useContext<ContextType>(HeroPendoCallIdContext)

  if (!context) {
    throw new Error('No HeroPendoCallIdContext found... Is the Provider set?')
  }

  return { ...context }
}

export default usePendoCallId
