import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import { FulfillmentSystem, TicketNote } from 'types/ticket'
import { orderBy } from 'lodash-es'
import { useServiceProxy } from '../kong'
import * as React from 'react'

/**
 * Returns sorted ticket notes if ECARs or combines ticket notes with qsp notes if Odyssey
 */
export const useTicketNotes = () => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const { note, ticketNumber, fulfillmentSystem } = useSelector(selectTicket)
  const serviceProxy = useServiceProxy()
  const [ticketNotes, setTicketNotes] = React.useState<any | null>(null)

  React.useEffect(() => {
    if (fulfillmentSystem === FulfillmentSystem.ODYSSEY && !ticketNotes) {
      const func = async () => {
        setLoading(true)
        const data = await serviceProxy<any>('get', `/serviceproxy/qsp`, {}, {}, { rentalAgreementNumber: ticketNumber })
        if (data && data.data[0]) {
          // updating the date from qsp to match the other ticket notes. then it can sort all notes by createDateTime
          const qspNotes = JSON.parse(JSON.stringify(data.data[0].notes).split('"dateTimeEntered":').join('"createDateTime":'))
          setTicketNotes(qspNotes)
        } else {
          setTicketNotes(null)
        }
        setLoading(false)
      }
      func()
    }
  }, [serviceProxy, fulfillmentSystem])

  if (fulfillmentSystem === FulfillmentSystem.ODYSSEY) {
    if (ticketNotes) {
      try {
        const combinedNotes = ticketNotes.reduce(
          // Ticket notes can be nullable
          (prev, notes) => (prev ? prev.concat(notes as TicketNote[]) : (notes as TicketNote[])),
          note,
        )
        const sortedNotes = orderBy(combinedNotes, 'createDateTime', 'desc')
        return { notes: sortedNotes }
      } catch (e) {
        console.error(e)
        setError('ODY Note Reduce Error' + JSON.stringify(e))
        return { notes: orderBy(note, 'createDateTime', 'desc') }
      }
    }
  }
  return { notes: orderBy(note, 'createDateTime', 'desc'), loading, error }
}

export default useTicketNotes
