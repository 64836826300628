import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdBusiness,
  MdClose,
  MdDirectionsCar,
  MdFormatAlignLeft,
  MdPerson,
  MdWarning,
  MdHistory,
  MdErrorOutline,
} from 'react-icons/md'
import { FaTruck } from 'react-icons/fa'
import { TransformedVehicle, TicketStatus } from 'types/ticket'
import { usePopupManager } from 'components/PopupManager'
import Section from 'components/Section'
import { selectCustomVinInput, selectTicket } from 'redux/appStore'
import { RentalIcon, ReturnIcon } from 'icons'
import DetailsItem from './DetailsItem'
import Branch from './Branch'
import Notes from './Notes'
import VehicleDetails from './VehicleDetails'
import Driver from './Driver'
import { capitalize, isNil } from 'lodash-es'
import styled from 'styled-components/macro'
import { MdBrightness1 } from 'react-icons/md'
import colors from 'theme/colors'
import { palette } from 'styled-tools'
import { pxToRem } from 'theme/utils'
import * as Ariakit from '@ariakit/react'
import { Flex, Heading } from 'components/primitives'
import { isCorporateAccount } from 'forms/CallerForm/CallerForm'
import CallHistory from './CallHistory'
import ConnectedDetailItem from './ConnectedDetailItem'
import { useCallEventsPagination } from 'hooks/events/call/useCallEventsPagination'
import { useSelector } from 'react-redux'
import { selectMapZoom } from 'redux/appStore'
import { useGoogleMaps } from 'hooks/map'
import { EventTypes } from 'types/events'
import { VehicleVinInputForm } from 'forms/DetailsForm/VehicleVinInputForm'
import { StyledLinkButton } from 'components/Button'
import { BranchInfoFragment } from 'types/branch'

const StyledTooltip = styled(Ariakit.Tooltip)`
  background-color: ${palette('calypso')};
  color: white;
  font-weight: 700;
  font-size: ${pxToRem(14)};
  padding: ${pxToRem(4)} ${pxToRem(8)};
`

const vehicleTitle = (vehicle: TransformedVehicle) => {
  const { year, make, model, color } = vehicle

  return `${year} ${make} ${model} (${color})`
}

const Details: React.FC = () => {
  const [isCustomVinInput, setIsCustomVinInput] = React.useState(false)
  const { t } = useTranslation()
  const { ticketNumber, vehicle, vehicleHistory, account, pickupInfo, returnInfo, primaryDriver, brandId, ticketStatus } =
    useSelector(selectTicket)
  const customVinInput = useSelector(selectCustomVinInput)

  const { open } = usePopupManager()
  const { map } = useGoogleMaps()
  const zoom = useSelector(selectMapZoom)
  const tooltip = Ariakit.useTooltipStore({ placement: 'left' })
  const { data, loading: callEventsLoading } = useCallEventsPagination()
  const isEscalatedEvent = data && data.events && data.events[0] && data.events[0].eventType === EventTypes.ESCALATE
  const isCustomVinPresent = !isNil(customVinInput) && customVinInput.length !== 0

  const isVehicleNull = isNil(vehicle) || (isNil(vehicle?.year) && isNil(vehicle?.make) && isNil(vehicle?.model))

  const pickupLocation = pickupInfo.branch as BranchInfoFragment
  const returnLocation = returnInfo.branch as BranchInfoFragment

  const driverItemProps = {
    icon: primaryDriver ? MdPerson : MdWarning,
    title: t(primaryDriver ? 'driverDetails' : 'notFound.driverProfile'),
    onClick: primaryDriver
      ? () => {
          open(<Driver {...primaryDriver} />)
        }
      : undefined,
  }

  const Status = (
    <Ariakit.TooltipProvider store={tooltip}>
      <Ariakit.TooltipAnchor store={tooltip}>
        <MdBrightness1
          size={16}
          color={
            ticketStatus === TicketStatus.OPEN
              ? colors.elm
              : ticketStatus === TicketStatus.CLOSED
                ? colors.cardinal
                : colors.buttercup
          }
        />
      </Ariakit.TooltipAnchor>
      <StyledTooltip store={{ ...tooltip }} aria-label="ticket-status">
        <Ariakit.TooltipArrow style={{ fill: colors.calypso }} />
        {ticketStatus}
      </StyledTooltip>
    </Ariakit.TooltipProvider>
  )

  return (
    <Section title={`${capitalize(brandId)} - ${t('RA')} ${ticketNumber} ${' - '} ${pickupInfo.groupBranchId}`} action={Status}>
      <Section.Body>
        {pickupLocation.locationType === 'TRUCK' ? <DetailsItem icon={FaTruck} title={'Truck Rental'} trucksWarning /> : null}
        <DetailsItem {...driverItemProps} />
        {account.name && <DetailsItem icon={isCorporateAccount(account) ? MdBusiness : undefined} title={account.name} />}
        <DetailsItem
          icon={RentalIcon}
          title={`${t('Rental')} ${t('date.standard', { date: new Date(pickupInfo.dateTime) })}`}
          onClick={() => {
            open(<Branch heading={t('Rental Branch')} {...pickupInfo} />)
            const { latitude, longitude } = pickupLocation

            if (map) {
              map.setCenter(new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude)))
              map.setZoom(zoom < 12 ? 12 : zoom)
            }
          }}
        />
        <DetailsItem
          icon={ReturnIcon}
          title={`${t('Return')} ${t('date.standard', { date: new Date(returnInfo.dateTime) })}`}
          onClick={() => {
            open(<Branch heading={t('Return Branch')} {...returnInfo} />)
            const { latitude, longitude } = returnLocation
            if (map) {
              map.setCenter(new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude)))
              map.setZoom(zoom < 12 ? 12 : zoom)
            }
          }}
        />
        <DetailsItem
          icon={MdFormatAlignLeft}
          title={t('Ticket Notes')}
          onClick={() => {
            open(<Notes />)
          }}
        />

        <DetailsItem
          icon={MdHistory}
          title={t('Call History')}
          onClick={() => {
            open(<CallHistory />)
          }}
          rightIcon={!callEventsLoading && isEscalatedEvent ? MdErrorOutline : undefined}
        />
        {!isCustomVinInput && isCustomVinPresent ? (
          <Flex style={{ marginTop: 8 }}>
            <ConnectedDetailItem icon={MdDirectionsCar} title={customVinInput} vin={''} unit={''} />
            <StyledLinkButton style={{ marginLeft: 'auto' }} onClick={() => setIsCustomVinInput(true)}>
              {t('Edit')}
            </StyledLinkButton>
          </Flex>
        ) : vehicle && !isCustomVinInput ? (
          <Flex style={{ alignItems: 'center', width: '100%' }}>
            <ConnectedDetailItem
              icon={MdDirectionsCar}
              title={vehicleTitle(vehicle)}
              onClick={() => {
                open(<VehicleDetails {...vehicle} />)
              }}
              vin={vehicle.vin}
              unit={vehicle.unitNumber}
            />
            {isVehicleNull && (
              <MdClose
                style={{ cursor: 'pointer', marginLeft: 8 }}
                color={colors.cardinal}
                onClick={() => setIsCustomVinInput(true)}
              />
            )}
          </Flex>
        ) : (
          <VehicleVinInputForm confirmClick={() => setIsCustomVinInput(false)} cancelClick={() => setIsCustomVinInput(false)} />
        )}
        {vehicleHistory && vehicleHistory.length > 0 && (
          <>
            <Heading as="h5">{t('vehicleHistory')}</Heading>
            {vehicleHistory.map(
              (vehicle, i) =>
                vehicle && (
                  <ConnectedDetailItem
                    key={i}
                    icon={MdDirectionsCar}
                    title={vehicleTitle(vehicle)}
                    onClick={() => {
                      open(<VehicleDetails {...vehicle} />)
                    }}
                    historicalVehicle
                    vin={vehicle.vin}
                    unit={vehicle.unitNumber}
                  />
                ),
            )}
          </>
        )}
      </Section.Body>
    </Section>
  )
}

export default Details
