export const millisecondsToSeconds = (time: number) => {
  let rem = time
  const milliseconds = rem % 1000
  rem = (rem - milliseconds) / 1000
  return rem
}

export const millisecondsToTime = (
  /** time in milliseconds */
  time: number,
): string => {
  let rem = time
  const milliseconds = rem % 1000
  rem = (rem - milliseconds) / 1000
  const secs = rem % 60
  rem = (rem - secs) / 60
  const mins = rem % 60
  const hrs = (rem - mins) / 60

  let result = `${mins}:${`00${secs}`.slice(-2)}`

  if (hrs) result = `${hrs}:${result}`

  return result
}
