import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading } from 'components/primitives'
import { millisecondsToTime } from 'utils/timer'
import { heroCallTimerContext } from 'pages/Ticket/routes/Default/Default'

export const CallerFormTimer: React.FC = () => {
  const { t } = useTranslation()
  const time = heroCallTimerContext.useCallTimerContext()

  return (
    <Heading as="h5">
      {t('New Call')} ({millisecondsToTime(time)})
    </Heading>
  )
}
