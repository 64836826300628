import * as React from 'react'
import Margin from 'components/Margin'
import Places from 'components/Places'
import PopupManager, { PopupDisplay } from 'components/PopupManager'
import ScrollBox from 'components/ScrollBox'
import Workflow from 'components/Workflow/Workflow'
import Section from 'components/Section'
import CallerForm, { CallerFormTimer } from 'forms/CallerForm'
import BackToSearchButton from './BackToSearchButton'
import CallLogger from './CallLogger'
import Details from './Details'
import DispatchEvents from './DispatchEvents'
import PendingLDR from './PendingLDR'
import ErrorBoundary from 'components/ErrorBoundary'
import { createCallTimerContext } from 'components/Timer/CallTimerContext'

const offset = 24
export const heroCallTimerContext = createCallTimerContext()

export const Default = () => {
  const [marginLeft, setMarginLeft] = React.useState<number>(0)
  const [maxHeight, setMaxHeight] = React.useState<number>(0)

  const ref = React.useCallback((node) => {
    if (node !== null) {
      setMarginLeft(node.offsetWidth + (offset - 16)) // horizontal offset starts from padding of node, remove before calculating
      setMaxHeight(node.offsetHeight - (offset + 24)) // subtract margin from bottom (48px from bottom of browser)
    }
  }, [])

  return (
    <ErrorBoundary>
      <heroCallTimerContext.Provider>
        <Margin spacing="md">
          <BackToSearchButton />
        </Margin>
        <Margin spacing="md">
          <Places />
        </Margin>
        <Workflow />
        <PopupManager>
          <ScrollBox ref={ref} style={{ paddingTop: 0 }}>
            {marginLeft && maxHeight && <PopupDisplay marginLeft={marginLeft} maxHeight={maxHeight} />}

            <Margin spacing="md">
              <Section id="top" title={<CallerFormTimer />}>
                <Section.Body>
                  <CallerForm />
                </Section.Body>
              </Section>
            </Margin>

            <Margin spacing="md">
              <Details />
            </Margin>
            <PendingLDR />
            <Margin spacing="md">
              <DispatchEvents />
            </Margin>
            <form autoComplete="off" onSubmit={() => {}}>
              <CallLogger />
            </form>
          </ScrollBox>
        </PopupManager>
      </heroCallTimerContext.Provider>
    </ErrorBoundary>
  )
}

export default Default
