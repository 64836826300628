import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getInitialConfigState } from 'redux/helpers/configHelpers'
import { AuthPayload, User } from 'types/auth'
import { ConfigState } from 'types/redux'

const initialState: ConfigState = getInitialConfigState()

const configSlice = createSlice({
  name: 'Config',
  initialState,
  reducers: {
    // Auth
    setCurrentUser: (state: ConfigState, action: PayloadAction<AuthPayload>) => {
      state.auth.user = action.payload.user
      return state
    },

    // LaunchDarkly
    setLaunchDarklyId: (state: ConfigState, action: PayloadAction<{ ldClientSideId: string }>) => {
      state.launchDarkly.ldClientSideId = action.payload.ldClientSideId
      return state
    },

    // Full Config slice
    resetConfig: (state: ConfigState) => {
      state.auth.user = initialState.auth.user
      state.launchDarkly.ldClientSideId = initialState.launchDarkly.ldClientSideId

      return state
    },
  },
})

export const { setCurrentUser, setLaunchDarklyId, resetConfig } = configSlice.actions

export default configSlice.reducer
