import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Divider from 'components/Divider'
import { FormApi } from 'final-form'
import * as Yup from 'yup'
import { Form } from 'components/FormManager'
import Input, { YesNoRadio } from 'components/Input'
import { VehicleSelect, StateSelect } from 'components/Select'
import { StepNavigation } from 'components/Step'
import { useReduxCallStoreActions } from 'hooks/events'
import { isRequired } from 'utils/validators'
import FormHeading from './FormHeading'
import DriversRadio from './DriversRadio'
import { Condition } from 'components/fields'
import { WorkflowAction } from 'components/Workflow'
import { CallTypes } from 'types/callstore'
import { EventTypes } from 'types/events'
import { useSelector } from 'react-redux'
import { selectCustomVinInput, selectFormsState, selectTicket } from 'redux/appStore'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-final-form'
import { format } from 'date-fns'
import { Label } from 'components/primitives'
import { useMount } from 'hooks/utils'

declare module 'types/form' {
  export interface Forms {
    LDRClaimCreation: FormApi
  }
}

export const LDRClaimCreation = () => {
  const form = useForm()
  const { drivers } = useSelector(selectTicket)
  const { update } = useReduxCallStoreActions()
  const { ldr } = useSelector(selectFormsState) as any
  const { claim, ...restValues } = ldr
  const { t } = useTranslation()
  const customVinInput = useSelector(selectCustomVinInput)
  const [startDate, setStartDate] = React.useState<Date | null>(claim?.date ? claim?.date : new Date())

  useMount(() => {
    handleDateChange(startDate)
  })

  function handleDateChange(date: Date | null) {
    if (date === null) return

    let selectedDate = date

    setStartDate(selectedDate)

    const dateFormatted = format(selectedDate, 'MM/DD/YYYY')

    //update the form
    form.change('date', dateFormatted)

    //update the redux store
    let values = {
      ...restValues,
      claim: { ...ldr.claim, date: dateFormatted },
    }
    update({ ldr: { ...values } })
  }

  return (
    <Form
      name="LDRClaimCreation"
      schema={LDRClaimCreationSchema}
      initialValues={claim}
      autoSaveDebounce={200}
      autoSaveSync={(updatedClaim) => {
        let values = {
          ...restValues,
          claim: { ...updatedClaim, customVin: customVinInput },
        }
        update({ ldr: { ...values } })
      }}
    >
      <FormHeading>{t('ldr.claim.heading')}</FormHeading>
      <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
      <Input.Hidden name="customVin" defaultValue={customVinInput || undefined} />
      {!customVinInput ? <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} /> : null}
      <Input.Hidden name="date" />
      <Label>{t('ldr.claim.dateOfLoss')}</Label>
      <DatePicker
        showIcon
        selected={startDate}
        onChange={handleDateChange}
        dateFormat="MM/dd/yyyy"
        placeholderText="  /  /    "
        onChangeRaw={(e: any) => e.preventDefault()}
        maxDate={new Date()}
      />
      <Label>{t('MM/DD/YYYY')}</Label>
      <br />
      <Input.Textarea label={`${t('ldr.claim.factsOfLoss')}*`} name="facts" />
      <StateSelect label={t('ldr.claim.state')} name="state" />
      <Divider />
      <YesNoRadio name="policeReport" label={t('ldr.claim.policeReport')}>
        <Input label={t('ldr.claim.policeReportNumber')} name="policeReportNumber" />
        <Input label={t('ldr.claim.precinct')} name="precinctName" />
        <Input label={t('ldr.claim.department')} name="policeDepartmentName" />
      </YesNoRadio>
      <Divider />
      <YesNoRadio name="roadsideTow" label={t('ldr.claim.otherTow')}></YesNoRadio>
      <Divider />
      <FormHeading>{t('ldr.claim.driver')}</FormHeading>
      <DriversRadio drivers={drivers as any} />
      <Divider />
      <Condition when="roadsideTow" is="yes">
        <WorkflowAction
          callType={CallTypes.RECOVERY}
          eventType={EventTypes.RECOVERY}
          additionalService={EventTypes.LDR}
          required
          label={t('recovery.dispatch')}
        />
      </Condition>
      <div style={{ height: 1, width: '100%', marginTop: 10 }} />
      <StepNavigation />
    </Form>
  )
}

export default LDRClaimCreation

const LDRClaimCreationSchema = Yup.object().shape({
  facts: Yup.string().nullable().required('Facts of Loss is required'),
  state: Yup.object().shape({
    label: Yup.string().nullable(),
    value: Yup.string().nullable().required('State/Province is required'),
  }),
  policeReport: Yup.string().nullable().required('Required'),
  policeReportNumber: Yup.string().nullable(),
  precinctName: Yup.string().nullable(),
  policeDepartmentName: Yup.string().nullable(),
  roadsideTow: Yup.string().nullable().required('Required'),
  towWho: Yup.string().nullable(),
  towWhere: Yup.string().nullable(),
  renterDriver: Yup.string().required('Driver is required'),
  driverFirstName: Yup.string().nullable(),
  driverLastName: Yup.string().nullable(),
  driverCallbackNumber: Yup.string().nullable(),
})
