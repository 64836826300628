export function getInitialConfigState() {
  return {
    auth: {
      user: null,
    },
    launchDarkly: {
      ldClientSideId: '',
    },
  }
}
