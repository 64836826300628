import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import { Branch } from 'types/global'
import Button from 'components/Button'
import { Condition } from 'components/fields'
import Input from 'components/Input'
import useFieldValue from 'hooks/utils/useFieldValue'
import { isRequired } from 'utils/validators'
import { Heading, Label } from '../../primitives'
import { VehicleAccommodationSelect } from '../../Select'
import Margin from '../../Margin/Margin'
import { DisabledVehicleSelection } from 'forms/CallForm/EscalateForm/DisabledVehicleSelection'
import { useCallEventsPagination } from 'hooks/events'
import { getTowEventInfo } from 'forms/CallForm/EscalateForm/utils'
import { getTowFromForm } from './utils'
import { BreakdownNote } from 'forms/CallForm/BreakdownDetails'
import { DynamoEvents } from 'types/events'
import { ServiceLocationType } from 'types/location'

type Props = {
  name: string
  branch: Branch
  onSetBranch?: (branch: Branch) => void
  isFirst: boolean
  isTowExchange?: boolean
}

const RequestExchange = ({ name, branch, onSetBranch, isFirst, isTowExchange }: Props) => {
  const { t } = useTranslation()
  const form = useForm()
  const notes = useFieldValue('exchangeNotes')
  const accommodationSelection = useFieldValue('vehicleAccommodationSelect')
  const accommodationNotes = useFieldValue('accommodationNotes')
  const disabledVehicle = useFieldValue('disabledVehicleSelect')
  const bypassReason = useFieldValue('bypassReasonSelect')
  const breakdownOption = useFieldValue('breakdownOption')
  const unsafe = useFieldValue('unsafe')
  const garageAccessible = useFieldValue('garageAccessible')
  const accessible = useFieldValue('accessible')
  const disabled = !notes || !accommodationSelection || !accommodationNotes || !disabledVehicle
  const { data } = useCallEventsPagination()
  const recentEvent = !isTowExchange && data ? getTowEventInfo(data.events) : getTowFromForm(form.getState().values)

  React.useEffect(() => {
    if (isTowExchange) {
      form.change('disabledVehicleSelect', {
        label: 'Towed',
        value: 'towed',
      })
    }
  }, [isTowExchange, form])

  React.useEffect(() => {
    if (!isTowExchange && recentEvent) {
      form.change('vehicleCondition', recentEvent.attributes.vehicleCondition)
      form.change('methodOfDelivery', recentEvent.attributes.towDirection === 'branch' ? 'Tow' : 'Ride-Along')
    }
  }, [isTowExchange, recentEvent, form])

  const handleRequest = () => {
    if (isFirst) {
      form.change('bypassReasonSelect', undefined)
    }
    form.change(name, {
      serviceLocationType: ServiceLocationType.EXCHANGE,
      ...branch,
    })
    form.change('serviceLocations.exchangeLocation', {
      serviceLocationType: ServiceLocationType.EXCHANGE,
      ...branch,
    })
    if (onSetBranch) onSetBranch(branch)
  }

  return (
    <Condition when="acceptReject" is={(v) => v !== 'reject'}>
      <Margin spacing="md">
        <VehicleAccommodationSelect name="vehicleAccommodationSelect" label={`${t('labels.vehicleAccommodationSelect')}*`} />
        <Input.Hidden name="vehicleAccommodationSelect.value" validate={isRequired(t('required.vehicleAccommodation'))} />
        <Input.Textarea
          spacing="md"
          label={`${t('labels.accommodationNotes')}`}
          name="accommodationNotes"
          validate={isRequired(t('required.accommodationNotesRequired'))}
        />
        <DisabledVehicleSelection recentEvent={recentEvent as DynamoEvents} />
      </Margin>
      <BreakdownNote
        breakdownOption={breakdownOption}
        unsafe={unsafe}
        garageAccessible={garageAccessible}
        accessible={accessible}
      />
      <Input.Textarea
        spacing="md"
        label={`${t('labels.describeIssue')}*`}
        name="exchangeNotes"
        validate={isRequired(t('required.notes'))}
      />
      {!isFirst ? (
        <>
          <Label>{`${t('labels.bypassReasonSelect')}*`}</Label>
          <Heading as="h6" style={{ marginBottom: '15px' }}>
            {' '}
            {bypassReason.label}{' '}
          </Heading>
        </>
      ) : null}
      <Button.Primary disabled={disabled} onClick={handleRequest} style={{ width: '100%' }}>
        {t('labels.requestExchange')}
      </Button.Primary>
    </Condition>
  )
}

export default RequestExchange
