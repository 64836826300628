import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { heroCallTimerContext } from 'pages/Ticket/routes/Default/Default'

type Props = {
  timeZone: string
}

export const LocalTime = ({ timeZone }: Props) => {
  heroCallTimerContext.useCallTimerContext() // force render to keep localTime time updated
  const { t } = useTranslation()

  const localTime = DateTime.local().setZone(timeZone).toFormat('t')

  return <>{localTime + t(` - Local Time`)}</>
}

export default LocalTime
