import {
  AaaCallType,
  AaaContactType,
  AaaVehicleType,
  AaaVehicle,
  AaaCommentType,
  AaaPhoneType,
  StandardServiceCall,
  FoiType,
  AaaServiceLocation,
} from 'types/aaa'
import { Brands, TroubleCode } from 'types/global'
import { EventTypes } from 'types/events'
import { FormattedCaller } from 'components/fields/Submit'
import { SoftService, Tow, TowExchange } from 'types/callstore'
import { createSoftService, addCustomerContact, addComment, createTowService } from 'utils/aaa'
import { useSelector } from 'react-redux'
import { selectCurrentUser, selectTicket } from 'redux/appStore'
import { ServiceLocationType } from 'types/location'
import { config } from 'config'
import { useCallerFormValues } from '../events'
import { TransformedDriver } from '../../types/ticket'
import { CountryCode, isSupportedCountry, parsePhoneNumber } from 'libphonenumber-js'
import { splitName } from 'utils/splitName'
import { isNil } from 'lodash'

const OrgCodes = {
  [Brands.ALAMO]: '928',
  [Brands.ENTERPRISE]: '925',
  [Brands.NATIONAL]: '929',
}

export const SoftServiceTroubleCodes: { [key: string]: TroubleCode } = {
  [EventTypes.TIRES_CHANGE_SPARE]: TroubleCode.T1,
  [EventTypes.JUMPSTART]: TroubleCode.T3,
  [EventTypes.LOCKOUT]: TroubleCode.T7,
  [EventTypes.FUEL]: TroubleCode.T5,
  [EventTypes.WINCH]: TroubleCode.T8,
  [EventTypes.TOW]: TroubleCode.T6,
}

export const useAAAStandardServices = (eventTypes: ReadonlyArray<EventTypes>) => {
  const currentUser = useSelector(selectCurrentUser)
  const {
    brandId,
    ticketNumber,
    pickupInfo: { groupBranchId },
  } = useSelector(selectTicket)
  const agentEID = currentUser!.nameID

  const externalId = (Number(String(Math.random()).slice(2)) + Date.now() + Math.round(performance.now())).toString(
    20, // max length external id AAA
  )
  // need country code prefix for the INT phone number comment to AAA
  const currentTicket = useSelector(selectTicket)
  const { drivers } = currentTicket
  const callerFormValues = useCallerFormValues(drivers as TransformedDriver[])

  return (
    service: SoftService | Tow | TowExchange,
    { callerName, formatted, countryCode, number, optIn }: FormattedCaller,
  ): StandardServiceCall[] => {
    return eventTypes
      .map((eventType) => {
        const standardService = {
          version: 1.1,
          externalId,
          customer: {
            customerId: ticketNumber,
            contact: {
              contactType: AaaContactType.CUSTOMER,
              phones: [],
            },
            organization: {
              code: OrgCodes[brandId],
              name: brandId,
            },
          },
          vehicle: {
            externalId: groupBranchId,
            vehicleType: AaaVehicleType.PS,
            unattended: false,
          } as AaaVehicle,
          service: {
            callType: AaaCallType.PARTNER,
            requestedServices: [],
            serviceLocations: [],
          },
          comments: [],
          channel: {
            channelType: 'CALL_CENTER',
            contact: {
              contactType: AaaContactType.AGENT,
              userId: agentEID,
            },
          },
        }

        let defaultCountry = (countryCode as string).toString().toUpperCase() as CountryCode
        if (!isSupportedCountry(defaultCountry)) defaultCountry = 'US'
        const phoneNumber = parsePhoneNumber(number, defaultCountry)

        const { firstName, lastName } = splitName(callerName)

        //Regex to remove non-numeric characters from formatted national number
        const sanitizedPhoneNumber = phoneNumber.formatNational().replace(/[^+\d]+/g, '')
        const numberWithTenDigits =
          sanitizedPhoneNumber.length < 10 ? '000 000 0000' : phoneNumber.formatNational() || number.substring(number.length - 10)

        let standard = addCustomerContact(standardService, {
          contactType: AaaContactType.CUSTOMER,
          firstName,
          lastName,
          phones: [
            {
              // Currently only accepts 10 digits and required
              phoneNumber: numberWithTenDigits,
              phoneType: AaaPhoneType.CELLULAR,
              smsOptIn: config.envName !== 'prod' ? false : !!optIn,
              preferred: true,
            },
          ],
        })

        standard = addComment(standard, `RA Number: ${ticketNumber}`)

        // International Callback Numbers need to be passed through as comments
        if ((countryCode as string).toLowerCase() !== 'us') {
          standard = addComment(standard, `INTL PHONE NUMBER: ${countryCode} ${phoneNumber.formatNational()}`)
        }

        if (eventType === EventTypes.TOW) {
          const towService = createTowService(standard, service as Tow, SoftServiceTroubleCodes[eventType])
          // 4 serviceLocations are only set for Deliver New Vehicle & Tow Disabled Vehicle workflow. Caller and Vehicle locations are different.
          if (
            towService.service.serviceLocations.length === 4 &&
            (service as TowExchange).towDirection === 'branch' &&
            (service as TowExchange).disabledVehiclePickup
          ) {
            if (isNil((service as TowExchange).dispatchRentalNotes)) return towService
            return splitReverseTwoWayTow(towService, (service as TowExchange).dispatchRentalNotes!)
          }
          return towService
        }
        return createSoftService(standard, service as SoftService, SoftServiceTroubleCodes[eventType])
      })
      .flat()
  }
}

export function splitReverseTwoWayTow(serviceRequest: StandardServiceCall, dispatchRentalNotes: string) {
  const serviceLocations = serviceRequest.service.serviceLocations

  const branchLocation = serviceLocations.find((loc) => loc.foi.foiType === FoiType.RENTAL) as AaaServiceLocation

  const vehicleDestinationLocation = serviceLocations.find(
    (loc) => loc.serviceLocationType === ServiceLocationType.TOW_DESTINATION,
  ) as AaaServiceLocation

  const vehicleLocation = serviceLocations.find((loc) => loc.foi.name === ServiceLocationType.VEHICLE) as AaaServiceLocation
  const callerLocation = serviceLocations.find((loc) => loc.foi.name === ServiceLocationType.CALLER) as AaaServiceLocation

  const branchTowLocations = [
    { ...branchLocation, serviceLocationType: ServiceLocationType.BREAKDOWN },
    { ...callerLocation, serviceLocationType: ServiceLocationType.TOW_DESTINATION },
  ].filter(Boolean)
  const vehicleTowLocations = [vehicleLocation, vehicleDestinationLocation].filter(Boolean)

  const branchTowRequest: StandardServiceCall = {
    ...serviceRequest,
    service: {
      ...serviceRequest.service,
      // we're only interested in the tow service which is first, strip all others
      requestedServices: serviceRequest.service.requestedServices.slice(0, 1),
      serviceLocations: branchTowLocations,
    },
    vehicle: {
      ...serviceRequest.vehicle,
      // unattended does not apply to rental pickup
      unattended: false,
      unattendedNotes: null,
      condition: null,
    },
    comments:
      // if INTL phone number comment exists, grab it
      // append or replace other comments with rental pick up notes
      serviceRequest.comments.length > 1
        ? serviceRequest.comments.slice(0, 1).concat({
            commentType: AaaCommentType.NORMAL,
            text: dispatchRentalNotes,
          })
        : [
            {
              commentType: AaaCommentType.NORMAL,
              text: dispatchRentalNotes,
            },
          ],
  }

  const vehicleTowRequest: StandardServiceCall = {
    ...serviceRequest,
    customer: {
      ...serviceRequest.customer,
      contact: {
        ...serviceRequest.customer.contact,
        phones: [
          {
            ...serviceRequest.customer.contact.phones![0],
            // customer will not want SMS updates for the unattended tow
            smsOptIn: false,
          },
        ],
      },
    },
    service: {
      ...serviceRequest.service,
      serviceLocations: vehicleTowLocations,
    },
  }

  return [branchTowRequest, vehicleTowRequest]
}

export default useAAAStandardServices
