import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Label, Paragraph } from 'components/primitives'
import Margin from 'components/Margin'
import { YesNoTriggers } from 'components/Input'
import { LocationLabels } from 'components/Input/Location'
import SmartAssignment from 'components/SmartAssignment'
import { isLocation, isParticipant, isBranch } from 'utils/typeChecks'
import IncidentLocation from 'components/IncidentLocation'
import { ExchangeBranchDisplay } from 'components/Exchange'
import { DynamoEvents } from 'types/events'
import { LocationPayload, LocationState } from 'types/location'
import { Participant } from 'types/global'
import { isNil } from 'lodash'

function TowServiceLocations(event: DynamoEvents) {
  const { t } = useTranslation()

  const {
    serviceLocations,
    agentEID,
    customerWithVehicle,
    network,
    exchangeNotes,
    vehicleAccommodationSelect,
    accommodationNotes,
    disabledVehicle,
    bypassReason,
  } = event.attributes

  const isWithVehicle = customerWithVehicle === YesNoTriggers.YES

  return (
    <Margin spacing="md">
      {Object.values(serviceLocations as LocationState).map((location: LocationPayload | null, i: number) => {
        const isLast = i === Object.values(serviceLocations).length - 1

        if (isLocation(location)) {
          const label = LocationLabels[location.serviceLocationType]

          return (
            <React.Fragment key={i}>
              <IncidentLocation label={t(label)} location={location} spacing={isLast && !isWithVehicle ? 'none' : 'md'} />
              {isWithVehicle && (
                <Margin spacing="md">
                  <Label>{t('labels.vehicleLocation')}</Label>
                  <Paragraph>{t('Customer is with vehicle')}</Paragraph>
                </Margin>
              )}
            </React.Fragment>
          )
        }

        if (isParticipant(location)) {
          const participantLocation = location as Participant | null

          if (isNil(participantLocation)) return null
          return (
            <Margin key={i} spacing={isLast ? 'none' : 'md'}>
              <Label>{t('labels.disabledVehicleDropoff')}</Label>
              <SmartAssignment.Participant participant={participantLocation} network={network?.value} showTooltip={false} />
            </Margin>
          )
        }

        if (isBranch(location)) {
          return (
            <ExchangeBranchDisplay
              key={i}
              branch={location}
              agentEID={agentEID}
              exchangeNotes={exchangeNotes}
              vehicleAccommodation={vehicleAccommodationSelect.label}
              accommodationNotes={accommodationNotes}
              disabledVehicle={disabledVehicle}
              bypassReason={bypassReason}
            />
          )
        }

        return null
      })}
    </Margin>
  )
}

export default TowServiceLocations
