import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Divider from 'components/Divider'
import { Flex, Label, Paragraph } from 'components/primitives'
import { LdrClaim, Insurance } from 'types/callstore'
import FormHeading from './Steps/FormHeading'
import { DriverPhone } from 'types/ticket'
import { TypeCountryOptions } from 'types/global'

type Props = {
  label: string
  children: React.ReactNode
}

export function Field({ label, children }: Props) {
  return (
    <Label spacing="md">
      {label}
      <Paragraph>{children}</Paragraph>
    </Label>
  )
}

export function LDRFields({ claimNumber, claim, callHistoryView = false }: LdrClaim) {
  const { t } = useTranslation()

  if (!claim || !!!claim.driverFirstName)
    return (
      <>
        <FormHeading>{t('ldr.confirmation.heading')}</FormHeading>
        {!claim ? null : <div>{t('Claim Has Been Redacted ')}</div>}
      </>
    )

  const {
    policeReportNumber = '--',
    precinctName = '--',
    policeDepartmentName = '--',
    towWho = '--',
    towWhere = '--',
    driverFirstName = '--',
    driverLastName = '--',
    driverCallbackNumber = '--',
    facts = '--',
    date = '--',
    state: { label: stateLabel = '--' },
    vehicle,
    customVin,
  } = claim

  return (
    <>
      <FormHeading>{t('ldr.confirmation.heading')}</FormHeading>
      {claimNumber && (
        <>
          <Field label={t('ldr.claimNumber')}>{claimNumber}</Field>
          <Divider />
        </>
      )}
      <Field label={t('ldr.confirmation.vehicle')}>{customVin ? `VIN: ${customVin}` : vehicle?.label || '--'}</Field>
      <Field label={t('ldr.confirmation.dateOfLoss')}>{date}</Field>
      <Field label={t('ldr.confirmation.factsOfLoss')}>{facts}</Field>
      <Field label={t('ldr.confirmation.state')}>{stateLabel}</Field>
      <Divider />
      <FormHeading>{t('ldr.confirmation.policeReport')}</FormHeading>
      {claim.policeReport === 'no' && <Field label={t('ldr.claim.policeReport')}>{t('No')}</Field>}
      {claim.policeReport === 'yes' && (
        <>
          <Field label={t('ldr.confirmation.policeReportNumber')}>{policeReportNumber}</Field>
          <Field label={t('ldr.confirmation.precinct')}>{precinctName}</Field>
          <Field label={t('ldr.confirmation.department')}>{policeDepartmentName}</Field>
        </>
      )}
      <FormHeading>{t('ldr.confirmation.towDetails')}</FormHeading>
      {claim.roadsideTow === 'no' && <Field label={t('ldr.claim.otherTow')}>{t('No')}</Field>}
      {claim.roadsideTow === 'yes' && (
        <>
          <Field label={t('ldr.confirmation.towWho')}>{towWho}</Field>
          <Field label={t('ldr.confirmation.towWhere')}>{towWhere}</Field>
        </>
      )}
      <FormHeading>{callHistoryView ? t('ldr.confirmation.driverCallHistory') : t('ldr.confirmation.driver')}</FormHeading>
      <Field label={t('ldr.confirmation.driverFirstName')}>{driverFirstName}</Field>
      <Field label={t('ldr.confirmation.driverLastName')}>{driverLastName}</Field>
      <Field label={t('ldr.confirmation.driverCallbackNumber')}>{driverCallbackNumber}</Field>
    </>
  )
}

export function InsuranceConfirmation({
  companyName = '--',
  claimNumber = '--',
  policyNumber = '--',
  effectiveDate = '--',
  expirationDate = '--',
  coverage = '--',
  contactFirstName = '--',
  contactLastName = '--',
  phone,
  email = '--',
}: Insurance) {
  const { t } = useTranslation()

  return (
    <>
      <Field label={t('Company Name')}>{companyName}</Field>
      <Field label={t('Claim Number')}>{claimNumber}</Field>
      <Field label={t('Policy Number')}>{policyNumber}</Field>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Field label={t('Effective Date')}>{effectiveDate}</Field>
        <div style={{ textAlign: 'right' }}>
          <Field label={t('Expiration Date')}>{expirationDate}</Field>
        </div>
      </Flex>
      <Field label={t('Coverage')}>{coverage}</Field>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Field label={t('Contact First Name')}>{contactFirstName}</Field>
        <div style={{ textAlign: 'right' }}>
          <Field label={t('Contact Last Name')}>{contactLastName}</Field>
        </div>
      </Flex>
      <PhoneDisplay phone={phone} />
      <Field label={t('Email')}>{email}</Field>
    </>
  )
}

export function PhoneDisplay({ phone }: { phone?: (DriverPhone & TypeCountryOptions) | null }) {
  const { t } = useTranslation()
  if (!phone) return null
  const { number, typeOption, countryOption } = phone
  return (
    <>
      <Field label={t('Country Code')}>{countryOption && countryOption.label ? countryOption.label : '--'}</Field>
      <Field label={t('Phone')}>{number || '--'}</Field>
      <Field label={t('Phone Type')}>{typeOption && typeOption.label ? typeOption.label : '--'}</Field>
    </>
  )
}

export default LDRFields
